<template>
  <div class="view-box">
    <div class="view-box-title">设备情况
      <div style="float: right">
        <a-button type="primary" ghost shape="round">当日</a-button>
        <a-button style="margin-left: 10px" type="primary" ghost shape="round">当月</a-button>
      </div></div>
    <div class="view-box-content">
      <a-row>
        <a-col :span="8">
          <PieChart2  :data="pie1Data" :classifyDate="classifyDate1" id="pie1"></PieChart2>
          <div style="width:100%;text-align: center">按设备类型统计</div>
        </a-col>
        <a-col :span="8">
          <PieChart2 @clickPie="equipmentKind" :data="pie2Data" :classifyDate="classifyDate2" id="pie2"></PieChart2>
          <div style="width:100%;text-align: center">按设备数量统计</div>
        </a-col>
        <a-col :span="8">
          <PieChart2 :data="pie3Data" :classifyDate="classifyDate3" id="pie3"></PieChart2>
          <div style="width:100%;text-align: center">按设备故障统计</div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
  import PieChart2 from './PieChart'

  export default {
    name: 'LeftTwo',
    components: { PieChart2 },
    data () {
      return {
        classifyDate1: "设备类型数",
        classifyDate2: "设备总数",
        classifyDate3: "设备故障",
        color: ['#FFCC99', '#CC6633', '#33CCFF'],
        data: [
          { type: '动力设备', value: 20 },
          { type: '一体化提升泵', value: 18 },
          { type: '电器设备', value: 32 },
          { type: '膜设备', value: 46 },
          { type: '检测设备', value: 46 },
        ],
        data1: [
          { type: '库存设备', value: 64 },
          { type: '在线设备', value: 98 },
        ],
        data2: [
          { type: '库存良好', value: 50 },
          { type: '库存损坏', value: 14 },
        ],
        data3: [
          { type: '在线良好', value: 68 },
          { type: '在线预警', value: 30 },
        ],
        pie1Data: [],
        pie2Data: [],
        pie3Data: []
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        this.pie1Data = this.data
        this.pie2Data = this.data1
        this.pie3Data = this.data2
      },
      equipmentKind (name) {
        if (name === '库存设备') {
          this.pie3Data = this.data2
        } else {
            this.pie3Data = this.data3
          }
        }
      }
    }
</script>