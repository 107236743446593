<template>
  <div class="view-box">
    <div class="view-box-title">巡检故障及处理情况
      <div style="float: right">
        <a-button type="primary" ghost shape="round">当日</a-button>
        <a-button style="margin-left: 10px" type="primary" ghost shape="round">当月</a-button>
      </div>
    </div>
    <div class="view-box-content">
      <a-row>
        <a-col :span="24">
          <GroupBar :data="data" id="groupBar"></GroupBar>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <GuidePie :data="test1" :ratio="ratioDate1" id="guidePie1"></GuidePie>
          <div style="width:100%;text-align: center;line-height: 0;padding-bottom: 13px">处理中</div>
        </a-col>
        <a-col :span="8">
          <GuidePie :data="test2" :ratio="ratioDate2" id="guidePie2"></GuidePie>
          <div style="width:100%;text-align: center;line-height: 0;padding-bottom: 13px">已处理</div>
        </a-col>
        <a-col :span="8">
          <GuidePie :data="test3" :ratio="ratioDate3" id="guidePie3"></GuidePie>
          <div style="width:100%;text-align: center;line-height: 0;padding-bottom: 13px">已完成</div>
        </a-col>
      </a-row>
    </div>
  </div>

</template>
<script>
  import GroupBar from '@/views/datav/homePage/Left/GroupBar'
  import GuidePie from '@/views/datav/homePage/Left/GuidePie'

  export default {
    name: 'LeftThree',
    components: { GroupBar, GuidePie },
    data () {
      return {
        ratioDate1: "占比33%",
        ratioDate2: "占比20%",
        ratioDate3: "占比47%",
        data: [
          { name: '已处理', 月份: '六月.', 月均降雨量: 20 },
          { name: '已处理', 月份: '七月.', 月均降雨量: 24 },
          { name: '已处理', 月份: '八月.', 月均降雨量: 35 },
          { name: '已处理', 月份: '九月.', 月均降雨量: 12 },
          { name: '已处理', 月份: '十月.', 月均降雨量: 29 },
          { name: '已处理', 月份: '十一月.', 月均降雨量: 34 },
          { name: '已处理', 月份: '十二月.', 月均降雨量: 29 },
          { name: '未处理', 月份: '一月.', 月均降雨量: 10 },
          { name: '未处理', 月份: '二月.', 月均降雨量: 4 },
          { name: '未处理', 月份: '三月.', 月均降雨量: 58 },
          { name: '未处理', 月份: '四月.', 月均降雨量: 23 },
          { name: '未处理', 月份: '五月', 月均降雨量: 39 },
          { name: '未处理', 月份: '六月.', 月均降雨量: 14 },
          { name: '未处理', 月份: '七月.', 月均降雨量: 10 },
          { name: '未处理', 月份: '八月.', 月均降雨量: 10 },
          { name: '未处理', 月份: '九月.', 月均降雨量: 5 },
          { name: '未处理', 月份: '十月.', 月均降雨量: 10 },
          { name: '未处理', 月份: '十一月.', 月均降雨量: 30 },
          { name: '未处理', 月份: '十二月.', 月均降雨量: 20 },
          { name: '已处理', 月份: '一月.', 月均降雨量: 18 },
          { name: '已处理', 月份: '三月.', 月均降雨量: 35 },
          { name: '已处理', 月份: '四月.', 月均降雨量: 41 },
          { name: '已处理', 月份: '五月', 月均降雨量: 27 },
        ],
        test1: [
          { type: '处理中', value: 30 },
          { type: '其他', value: 120 },
        ],
        test2: [
          { type: '已处理', value: 20 },
          { type: '其他', value: 70 },
        ],
        test3: [
          { type: '已完成', value: 40 },
          { type: '其他', value: 40 },
    ]
      }
    }
  }
</script>