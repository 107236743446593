<template>
  <div>
    <div style="z-index: 100;position: relative;top: 20px;left: 20px;">
      <div
        style="width: 236px;float: left;text-align:left;font-size: large;font-weight: bold;line-height: 30px;padding-left: 6px;">
        宁夏自治区污水处理一览图
      </div>
      <dv-decoration-3 style="height: 32px;width: 236px"/>
    </div>
    <div id="map"></div>
  </div>
</template>

<script>
  import { Scene, PointLayer, LineLayer, PolygonLayer } from '@antv/l7'
  import { GaodeMap } from '@antv/l7-maps'

  const pointData = {
    'type': 'FeatureCollection',
    'features': [{
      'type': 'Feature',
      'properties': {},
      'geometry': {
        'type': 'MultiPoint',
        'coordinates': [
          [105.97000122070312, 37.61967039695652],
          [105.63079833984375, 37.292628004510924],
          [105.69259643554688, 36.99377838872517],
          [106.6181946, 38.62223528],
          [106.4870453, 38.49659352],
          [106.3517761, 38.45251326]
        ]
      }
    }]
  }
  const lineData = {
    'type': 'FeatureCollection',
    'features': [{
      'type': 'Feature',
      'properties': {},
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [105.97000122070312, 37.61967039695652],
          [105.63079833984375, 37.292628004510924],
          [105.69259643554688, 36.99377838872517],
          [106.6181946, 38.62223528],
          [106.4870453, 38.49659352],
          [106.3517761, 38.45251326]
        ]
      }
    }]
  }
  const polygonData = {
    'type': 'FeatureCollection',
    'features': [
      {
        'type': 'Feature',
        'properties': {
          'adcode': 640000,
          'name': '宁夏',
          'center': [
            106.278393,
            38.46747
          ],
          'centroid': [
            106.491637,
            38.465854
          ],
          'childrenNum': 0,
          'level': 'district',
          'acroutes': [
            100000,
            640000,
            640100
          ],
          'parent': {
            'adcode': 640000
          }
        },
        'geometry': {
          'type': 'MultiPolygon',
          'coordinates': [
            [
              [
                [
                  106.46269,
                  35.71514
                ],
                [
                  106.45711,
                  35.70761
                ],
                [
                  106.44901,
                  35.69697
                ],
                [
                  106.44920,
                  35.69122
                ],
                [
                  106.44675,
                  35.69070
                ],
                [
                  106.43749,
                  35.68837
                ],
                [
                  106.43434,
                  35.67883
                ],
                [
                  106.43742,
                  35.65982
                ],
                [
                  106.44707,
                  35.64835
                ],
                [
                  106.45979,
                  35.64013
                ],
                [
                  106.45284,
                  35.63450
                ],
                [
                  106.45571,
                  35.62556
                ],
                [
                  106.46518,
                  35.61815
                ],
                [
                  106.46466,
                  35.60470
                ],
                [
                  106.47083,
                  35.59942
                ],
                [
                  106.47457,
                  35.58609
                ],
                [
                  106.47541,
                  35.58076
                ],
                [
                  106.48207,
                  35.57585
                ],
                [
                  106.48037,
                  35.57507
                ],
                [
                  106.46580,
                  35.57964
                ],
                [
                  106.45498,
                  35.56907
                ],
                [
                  106.44998,
                  35.55514
                ],
                [
                  106.45205,
                  35.54764
                ],
                [
                  106.45113,
                  35.54074
                ],
                [
                  106.44953,
                  35.53589
                ],
                [
                  106.44012,
                  35.52722
                ],
                [
                  106.44165,
                  35.52126
                ],
                [
                  106.44888,
                  35.51769
                ],
                [
                  106.44818,
                  35.51185
                ],
                [
                  106.46135,
                  35.50265
                ],
                [
                  106.46390,
                  35.49323
                ],
                [
                  106.46515,
                  35.48148
                ],
                [
                  106.46935,
                  35.47737
                ],
                [
                  106.48175,
                  35.47946
                ],
                [
                  106.48619,
                  35.48212
                ],
                [
                  106.48485,
                  35.46501
                ],
                [
                  106.48544,
                  35.44555
                ],
                [
                  106.49707,
                  35.42234
                ],
                [
                  106.50162,
                  35.41763
                ],
                [
                  106.49965,
                  35.41108
                ],
                [
                  106.49182,
                  35.39342
                ],
                [
                  106.49567,
                  35.38925
                ],
                [
                  106.49539,
                  35.38437
                ],
                [
                  106.49431,
                  35.37538
                ],
                [
                  106.49968,
                  35.37088
                ],
                [
                  106.49925,
                  35.36712
                ],
                [
                  106.50153,
                  35.36061
                ],
                [
                  106.50616,
                  35.35862
                ],
                [
                  106.50360,
                  35.35644
                ],
                [
                  106.49566,
                  35.35430
                ],
                [
                  106.48979,
                  35.35084
                ],
                [
                  106.48780,
                  35.34180
                ],
                [
                  106.48890,
                  35.33317
                ],
                [
                  106.48403,
                  35.32525
                ],
                [
                  106.47795,
                  35.31903
                ],
                [
                  106.47176,
                  35.31349
                ],
                [
                  106.46083,
                  35.31064
                ],
                [
                  106.45571,
                  35.30913
                ],
                [
                  106.44341,
                  35.30048
                ],
                [
                  106.43648,
                  35.29956
                ],
                [
                  106.42658,
                  35.29049
                ],
                [
                  106.42007,
                  35.28180
                ],
                [
                  106.41304,
                  35.28157
                ],
                [
                  106.40680,
                  35.28075
                ],
                [
                  106.39939,
                  35.27723
                ],
                [
                  106.38382,
                  35.27348
                ],
                [
                  106.37370,
                  35.27776
                ],
                [
                  106.36618,
                  35.27412
                ],
                [
                  106.37276,
                  35.25378
                ],
                [
                  106.36597,
                  35.24126
                ],
                [
                  106.36025,
                  35.24325
                ],
                [
                  106.35622,
                  35.25129
                ],
                [
                  106.34742,
                  35.25619
                ],
                [
                  106.33806,
                  35.25489
                ],
                [
                  106.33112,
                  35.26293
                ],
                [
                  106.32470,
                  35.26753
                ],
                [
                  106.32449,
                  35.27676
                ],
                [
                  106.31866,
                  35.28358
                ],
                [
                  106.31196,
                  35.29116
                ],
                [
                  106.30106,
                  35.29373
                ],
                [
                  106.29117,
                  35.30128
                ],
                [
                  106.29053,
                  35.31141
                ],
                [
                  106.28419,
                  35.32072
                ],
                [
                  106.27420,
                  35.33257
                ],
                [
                  106.26236,
                  35.33340
                ],
                [
                  106.25560,
                  35.34610
                ],
                [
                  106.24638,
                  35.35572
                ],
                [
                  106.25242,
                  35.36403
                ],
                [
                  106.25186,
                  35.37566
                ],
                [
                  106.24441,
                  35.38432
                ],
                [
                  106.24226,
                  35.40509
                ],
                [
                  106.23196,
                  35.41131
                ],
                [
                  106.22218,
                  35.41323
                ],
                [
                  106.21521,
                  35.41252
                ],
                [
                  106.20000,
                  35.41023
                ],
                [
                  106.19156,
                  35.41664
                ],
                [
                  106.18613,
                  35.42969
                ],
                [
                  106.18169,
                  35.42716
                ],
                [
                  106.18269,
                  35.43284
                ],
                [
                  106.17708,
                  35.43880
                ],
                [
                  106.17029,
                  35.43590
                ],
                [
                  106.16337,
                  35.42126
                ],
                [
                  106.15337,
                  35.41524
                ],
                [
                  106.13256,
                  35.39842
                ],
                [
                  106.12656,
                  35.39634
                ],
                [
                  106.12516,
                  35.38842
                ],
                [
                  106.12382,
                  35.38372
                ],
                [
                  106.12166,
                  35.37726
                ],
                [
                  106.12078,
                  35.37489
                ],
                [
                  106.11550,
                  35.37548
                ],
                [
                  106.11166,
                  35.37106
                ],
                [
                  106.11498,
                  35.36336
                ],
                [
                  106.11381,
                  35.36180
                ],
                [
                  106.10846,
                  35.36618
                ],
                [
                  106.10465,
                  35.37791
                ],
                [
                  106.10741,
                  35.38361
                ],
                [
                  106.10538,
                  35.38883
                ],
                [
                  106.10114,
                  35.39176
                ],
                [
                  106.10269,
                  35.40045
                ],
                [
                  106.08908,
                  35.40245
                ],
                [
                  106.08480,
                  35.40892
                ],
                [
                  106.08735,
                  35.41444
                ],
                [
                  106.08357,
                  35.42326
                ],
                [
                  106.08076,
                  35.43625
                ],
                [
                  106.07856,
                  35.44289
                ],
                [
                  106.07367,
                  35.45075
                ],
                [
                  106.07384,
                  35.45981
                ],
                [
                  106.07481,
                  35.47102
                ],
                [
                  106.07157,
                  35.48636
                ],
                [
                  106.08785,
                  35.48666
                ],
                [
                  106.09576,
                  35.49443
                ],
                [
                  106.10251,
                  35.50092
                ],
                [
                  106.09065,
                  35.50991
                ],
                [
                  106.07773,
                  35.51026
                ],
                [
                  106.07419,
                  35.50733
                ],
                [
                  106.07351,
                  35.50014
                ],
                [
                  106.07215,
                  35.49520
                ],
                [
                  106.06861,
                  35.50132
                ],
                [
                  106.06343,
                  35.50282
                ],
                [
                  106.05986,
                  35.50171
                ],
                [
                  106.05678,
                  35.50143
                ],
                [
                  106.03168,
                  35.49590
                ],
                [
                  106.01863,
                  35.49675
                ],
                [
                  106.01800,
                  35.50069
                ],
                [
                  106.01421,
                  35.50578
                ],
                [
                  106.01775,
                  35.50894
                ],
                [
                  106.01822,
                  35.52022
                ],
                [
                  106.01041,
                  35.52479
                ],
                [
                  106.00304,
                  35.52317
                ],
                [
                  105.99290,
                  35.52949
                ],
                [
                  105.98241,
                  35.53092
                ],
                [
                  105.96623,
                  35.53334
                ],
                [
                  105.93704,
                  35.53579
                ],
                [
                  105.91727,
                  35.53878
                ],
                [
                  105.90510,
                  35.54404
                ],
                [
                  105.89341,
                  35.54515
                ],
                [
                  105.88239,
                  35.54235
                ],
                [
                  105.87989,
                  35.53694
                ],
                [
                  105.87073,
                  35.53982
                ],
                [
                  105.86326,
                  35.53582
                ],
                [
                  105.85975,
                  35.51663
                ],
                [
                  105.85276,
                  35.50965
                ],
                [
                  105.84416,
                  35.48979
                ],
                [
                  105.83787,
                  35.49808
                ],
                [
                  105.83620,
                  35.51807
                ],
                [
                  105.83767,
                  35.52504
                ],
                [
                  105.83647,
                  35.53830
                ],
                [
                  105.83148,
                  35.54099
                ],
                [
                  105.82599,
                  35.54132
                ],
                [
                  105.82932,
                  35.54651
                ],
                [
                  105.82264,
                  35.55144
                ],
                [
                  105.82454,
                  35.55858
                ],
                [
                  105.82149,
                  35.56575
                ],
                [
                  105.81564,
                  35.57644
                ],
                [
                  105.80950,
                  35.57357
                ],
                [
                  105.80340,
                  35.56929
                ],
                [
                  105.80066,
                  35.56670
                ],
                [
                  105.79921,
                  35.56481
                ],
                [
                  105.78882,
                  35.57437
                ],
                [
                  105.78136,
                  35.58080
                ],
                [
                  105.78053,
                  35.58694
                ],
                [
                  105.78463,
                  35.59270
                ],
                [
                  105.77716,
                  35.59984
                ],
                [
                  105.76094,
                  35.60280
                ],
                [
                  105.75631,
                  35.61201
                ],
                [
                  105.74969,
                  35.61277
                ],
                [
                  105.75021,
                  35.61440
                ],
                [
                  105.75874,
                  35.61438
                ],
                [
                  105.76433,
                  35.62881
                ],
                [
                  105.75722,
                  35.63473
                ],
                [
                  105.75092,
                  35.62876
                ],
                [
                  105.74523,
                  35.62547
                ],
                [
                  105.74550,
                  35.63596
                ],
                [
                  105.73968,
                  35.64189
                ],
                [
                  105.72823,
                  35.63991
                ],
                [
                  105.72127,
                  35.64481
                ],
                [
                  105.71524,
                  35.64767
                ],
                [
                  105.72082,
                  35.65988
                ],
                [
                  105.72053,
                  35.66503
                ],
                [
                  105.72337,
                  35.67190
                ],
                [
                  105.71422,
                  35.67922
                ],
                [
                  105.70627,
                  35.68327
                ],
                [
                  105.70285,
                  35.68631
                ],
                [
                  105.68924,
                  35.68386
                ],
                [
                  105.69132,
                  35.69492
                ],
                [
                  105.69901,
                  35.69852
                ],
                [
                  105.71299,
                  35.70805
                ],
                [
                  105.71723,
                  35.71713
                ],
                [
                  105.72202,
                  35.70424
                ],
                [
                  105.73171,
                  35.69397
                ],
                [
                  105.74709,
                  35.70145
                ],
                [
                  105.76007,
                  35.72565
                ],
                [
                  105.75390,
                  35.73067
                ],
                [
                  105.74969,
                  35.72921
                ],
                [
                  105.74511,
                  35.73057
                ],
                [
                  105.74140,
                  35.73155
                ],
                [
                  105.73549,
                  35.72636
                ],
                [
                  105.72920,
                  35.72947
                ],
                [
                  105.72108,
                  35.73277
                ],
                [
                  105.71305,
                  35.73757
                ],
                [
                  105.70333,
                  35.73495
                ],
                [
                  105.69364,
                  35.73585
                ],
                [
                  105.69053,
                  35.74200
                ],
                [
                  105.68258,
                  35.74623
                ],
                [
                  105.67637,
                  35.73934
                ],
                [
                  105.67384,
                  35.73826
                ],
                [
                  105.67108,
                  35.74833
                ],
                [
                  105.66523,
                  35.75000
                ],
                [
                  105.65943,
                  35.74728
                ],
                [
                  105.65245,
                  35.74342
                ],
                [
                  105.64407,
                  35.74023
                ],
                [
                  105.63099,
                  35.73529
                ],
                [
                  105.62272,
                  35.73198
                ],
                [
                  105.61584,
                  35.72921
                ],
                [
                  105.59769,
                  35.72420
                ],
                [
                  105.59309,
                  35.71991
                ],
                [
                  105.59439,
                  35.71500
                ],
                [
                  105.58934,
                  35.71661
                ],
                [
                  105.58225,
                  35.71618
                ],
                [
                  105.57015,
                  35.71718
                ],
                [
                  105.56902,
                  35.70839
                ],
                [
                  105.56613,
                  35.69032
                ],
                [
                  105.56014,
                  35.69142
                ],
                [
                  105.56028,
                  35.69842
                ],
                [
                  105.56726,
                  35.71114
                ],
                [
                  105.56083,
                  35.72344
                ],
                [
                  105.54488,
                  35.72795
                ],
                [
                  105.53487,
                  35.72333
                ],
                [
                  105.52839,
                  35.72696
                ],
                [
                  105.51660,
                  35.72400
                ],
                [
                  105.50398,
                  35.71896
                ],
                [
                  105.50453,
                  35.72099
                ],
                [
                  105.50169,
                  35.72392
                ],
                [
                  105.49462,
                  35.72381
                ],
                [
                  105.49252,
                  35.72527
                ],
                [
                  105.48446,
                  35.72809
                ],
                [
                  105.47584,
                  35.74209
                ],
                [
                  105.46940,
                  35.75575
                ],
                [
                  105.45984,
                  35.75348
                ],
                [
                  105.45507,
                  35.75512
                ],
                [
                  105.45750,
                  35.77556
                ],
                [
                  105.44581,
                  35.78380
                ],
                [
                  105.43207,
                  35.78564
                ],
                [
                  105.43018,
                  35.81735
                ],
                [
                  105.42358,
                  35.82368
                ],
                [
                  105.41519,
                  35.82419
                ],
                [
                  105.40598,
                  35.82571
                ],
                [
                  105.38889,
                  35.81194
                ],
                [
                  105.38268,
                  35.80409
                ],
                [
                  105.38712,
                  35.81689
                ],
                [
                  105.38831,
                  35.82408
                ],
                [
                  105.37739,
                  35.82476
                ],
                [
                  105.37273,
                  35.84377
                ],
                [
                  105.38406,
                  35.84745
                ],
                [
                  105.39694,
                  35.84733
                ],
                [
                  105.39329,
                  35.86495
                ],
                [
                  105.37633,
                  35.87458
                ],
                [
                  105.35865,
                  35.88067
                ],
                [
                  105.35000,
                  35.87968
                ],
                [
                  105.33467,
                  35.88846
                ],
                [
                  105.32792,
                  35.90781
                ],
                [
                  105.33464,
                  35.90647
                ],
                [
                  105.33791,
                  35.91697
                ],
                [
                  105.33477,
                  35.92909
                ],
                [
                  105.32643,
                  35.93703
                ],
                [
                  105.32663,
                  35.94612
                ],
                [
                  105.33213,
                  35.96356
                ],
                [
                  105.33354,
                  35.98930
                ],
                [
                  105.33325,
                  36.00147
                ],
                [
                  105.33803,
                  36.00437
                ],
                [
                  105.34109,
                  36.00973
                ],
                [
                  105.33882,
                  36.01830
                ],
                [
                  105.34074,
                  36.02443
                ],
                [
                  105.34371,
                  36.03225
                ],
                [
                  105.34832,
                  36.03200
                ],
                [
                  105.35840,
                  36.03499
                ],
                [
                  105.36357,
                  36.03405
                ],
                [
                  105.36297,
                  36.03766
                ],
                [
                  105.36029,
                  36.04486
                ],
                [
                  105.36674,
                  36.05108
                ],
                [
                  105.37545,
                  36.05710
                ],
                [
                  105.38429,
                  36.06007
                ],
                [
                  105.39090,
                  36.06097
                ],
                [
                  105.39732,
                  36.06989
                ],
                [
                  105.40385,
                  36.07445
                ],
                [
                  105.40773,
                  36.07521
                ],
                [
                  105.41457,
                  36.08429
                ],
                [
                  105.42100,
                  36.09119
                ],
                [
                  105.42854,
                  36.09864
                ],
                [
                  105.43375,
                  36.09709
                ],
                [
                  105.43838,
                  36.09968
                ],
                [
                  105.44660,
                  36.09970
                ],
                [
                  105.45343,
                  36.10485
                ],
                [
                  105.45686,
                  36.10219
                ],
                [
                  105.47026,
                  36.09748
                ],
                [
                  105.48953,
                  36.10246
                ],
                [
                  105.49495,
                  36.11163
                ],
                [
                  105.49846,
                  36.12178
                ],
                [
                  105.49568,
                  36.12762
                ],
                [
                  105.49774,
                  36.13427
                ],
                [
                  105.50714,
                  36.13999
                ],
                [
                  105.50823,
                  36.15288
                ],
                [
                  105.49567,
                  36.16090
                ],
                [
                  105.48970,
                  36.17734
                ],
                [
                  105.48742,
                  36.18821
                ],
                [
                  105.48172,
                  36.19375
                ],
                [
                  105.47652,
                  36.20762
                ],
                [
                  105.47355,
                  36.21474
                ],
                [
                  105.45724,
                  36.22423
                ],
                [
                  105.45298,
                  36.23682
                ],
                [
                  105.46227,
                  36.25252
                ],
                [
                  105.45479,
                  36.26799
                ],
                [
                  105.46901,
                  36.27510
                ],
                [
                  105.47287,
                  36.29302
                ],
                [
                  105.45728,
                  36.30763
                ],
                [
                  105.44894,
                  36.32346
                ],
                [
                  105.43475,
                  36.32517
                ],
                [
                  105.42659,
                  36.32856
                ],
                [
                  105.42832,
                  36.33919
                ],
                [
                  105.42703,
                  36.34836
                ],
                [
                  105.41290,
                  36.35373
                ],
                [
                  105.41009,
                  36.35855
                ],
                [
                  105.40979,
                  36.36553
                ],
                [
                  105.42209,
                  36.36944
                ],
                [
                  105.42091,
                  36.37904
                ],
                [
                  105.40391,
                  36.39434
                ],
                [
                  105.40206,
                  36.42042
                ],
                [
                  105.39729,
                  36.43407
                ],
                [
                  105.38761,
                  36.44080
                ],
                [
                  105.36951,
                  36.43701
                ],
                [
                  105.37463,
                  36.45167
                ],
                [
                  105.37229,
                  36.46881
                ],
                [
                  105.37301,
                  36.48816
                ],
                [
                  105.32866,
                  36.53311
                ],
                [
                  105.32102,
                  36.53723
                ],
                [
                  105.30924,
                  36.53137
                ],
                [
                  105.30315,
                  36.52602
                ],
                [
                  105.29788,
                  36.52507
                ],
                [
                  105.28925,
                  36.52876
                ],
                [
                  105.27724,
                  36.53123
                ],
                [
                  105.26121,
                  36.54159
                ],
                [
                  105.25566,
                  36.54831
                ],
                [
                  105.27450,
                  36.55496
                ],
                [
                  105.27567,
                  36.57996
                ],
                [
                  105.27203,
                  36.59283
                ],
                [
                  105.26573,
                  36.60074
                ],
                [
                  105.24585,
                  36.61576
                ],
                [
                  105.23576,
                  36.62412
                ],
                [
                  105.22352,
                  36.62505
                ],
                [
                  105.23419,
                  36.64657
                ],
                [
                  105.23144,
                  36.66149
                ],
                [
                  105.21206,
                  36.68172
                ],
                [
                  105.20549,
                  36.70317
                ],
                [
                  105.21486,
                  36.72941
                ],
                [
                  105.22398,
                  36.73518
                ],
                [
                  105.23036,
                  36.73269
                ],
                [
                  105.23664,
                  36.73519
                ],
                [
                  105.24023,
                  36.73756
                ],
                [
                  105.24623,
                  36.74191
                ],
                [
                  105.25680,
                  36.74226
                ],
                [
                  105.27463,
                  36.74785
                ],
                [
                  105.27783,
                  36.75420
                ],
                [
                  105.28562,
                  36.75232
                ],
                [
                  105.28973,
                  36.75366
                ],
                [
                  105.30050,
                  36.74831
                ],
                [
                  105.31167,
                  36.74603
                ],
                [
                  105.31892,
                  36.74705
                ],
                [
                  105.32484,
                  36.75011
                ],
                [
                  105.32708,
                  36.75257
                ],
                [
                  105.32918,
                  36.75818
                ],
                [
                  105.33187,
                  36.76284
                ],
                [
                  105.33900,
                  36.77497
                ],
                [
                  105.33734,
                  36.78579
                ],
                [
                  105.33258,
                  36.79819
                ],
                [
                  105.33490,
                  36.81185
                ],
                [
                  105.32005,
                  36.81631
                ],
                [
                  105.29911,
                  36.82650
                ],
                [
                  105.30158,
                  36.83664
                ],
                [
                  105.29239,
                  36.85150
                ],
                [
                  105.27794,
                  36.85629
                ],
                [
                  105.27937,
                  36.86577
                ],
                [
                  105.27885,
                  36.87215
                ],
                [
                  105.25653,
                  36.88702
                ],
                [
                  105.24951,
                  36.89261
                ],
                [
                  105.24899,
                  36.89879
                ],
                [
                  105.22864,
                  36.89516
                ],
                [
                  105.21234,
                  36.90078
                ],
                [
                  105.19340,
                  36.90095
                ],
                [
                  105.19096,
                  36.89460
                ],
                [
                  105.18066,
                  36.89176
                ],
                [
                  105.18035,
                  36.90738
                ],
                [
                  105.17913,
                  36.92055
                ],
                [
                  105.18864,
                  36.93467
                ],
                [
                  105.17628,
                  36.95987
                ],
                [
                  105.17315,
                  36.97516
                ],
                [
                  105.17092,
                  36.98352
                ],
                [
                  105.16395,
                  36.99832
                ],
                [
                  105.14042,
                  36.99996
                ],
                [
                  105.11832,
                  37.00358
                ],
                [
                  105.09481,
                  37.00869
                ],
                [
                  105.06528,
                  37.01785
                ],
                [
                  105.05262,
                  37.02693
                ],
                [
                  105.03150,
                  37.01255
                ],
                [
                  105.01642,
                  37.02418
                ],
                [
                  105.00723,
                  37.03829
                ],
                [
                  104.97767,
                  37.03882
                ],
                [
                  104.95772,
                  37.03636
                ],
                [
                  104.94667,
                  37.05098
                ],
                [
                  104.94696,
                  37.08691
                ],
                [
                  104.93101,
                  37.09048
                ],
                [
                  104.91464,
                  37.10346
                ],
                [
                  104.91426,
                  37.11931
                ],
                [
                  104.89614,
                  37.13336
                ],
                [
                  104.89057,
                  37.15846
                ],
                [
                  104.87093,
                  37.17260
                ],
                [
                  104.86178,
                  37.18324
                ],
                [
                  104.85839,
                  37.19897
                ],
                [
                  104.85405,
                  37.21611
                ],
                [
                  104.82636,
                  37.22893
                ],
                [
                  104.80947,
                  37.23474
                ],
                [
                  104.79258,
                  37.23999
                ],
                [
                  104.77553,
                  37.25077
                ],
                [
                  104.75510,
                  37.24282
                ],
                [
                  104.73719,
                  37.23305
                ],
                [
                  104.72290,
                  37.22012
                ],
                [
                  104.70080,
                  37.21098
                ],
                [
                  104.67737,
                  37.20822
                ],
                [
                  104.65511,
                  37.21029
                ],
                [
                  104.64212,
                  37.20843
                ],
                [
                  104.61398,
                  37.22533
                ],
                [
                  104.60244,
                  37.24960
                ],
                [
                  104.61398,
                  37.27105
                ],
                [
                  104.61502,
                  37.27870
                ],
                [
                  104.62243,
                  37.27823
                ],
                [
                  104.62624,
                  37.28381
                ],
                [
                  104.62154,
                  37.29191
                ],
                [
                  104.62460,
                  37.29906
                ],
                [
                  104.63204,
                  37.29908
                ],
                [
                  104.64466,
                  37.29137
                ],
                [
                  104.65126,
                  37.28958
                ],
                [
                  104.65494,
                  37.29252
                ],
                [
                  104.65673,
                  37.29631
                ],
                [
                  104.66257,
                  37.29776
                ],
                [
                  104.66487,
                  37.30043
                ],
                [
                  104.66705,
                  37.31222
                ],
                [
                  104.67626,
                  37.31923
                ],
                [
                  104.68239,
                  37.31898
                ],
                [
                  104.69098,
                  37.32128
                ],
                [
                  104.70408,
                  37.33052
                ],
                [
                  104.71113,
                  37.32893
                ],
                [
                  104.71503,
                  37.33311
                ],
                [
                  104.71813,
                  37.33840
                ],
                [
                  104.71757,
                  37.34940
                ],
                [
                  104.71797,
                  37.35187
                ],
                [
                  104.70588,
                  37.34879
                ],
                [
                  104.69380,
                  37.35087
                ],
                [
                  104.67742,
                  37.35443
                ],
                [
                  104.66803,
                  37.37522
                ],
                [
                  104.67592,
                  37.38515
                ],
                [
                  104.67793,
                  37.39873
                ],
                [
                  104.68244,
                  37.40939
                ],
                [
                  104.66385,
                  37.41489
                ],
                [
                  104.63893,
                  37.42348
                ],
                [
                  104.62326,
                  37.41986
                ],
                [
                  104.58829,
                  37.42611
                ],
                [
                  104.53829,
                  37.43168
                ],
                [
                  104.52226,
                  37.43421
                ],
                [
                  104.47402,
                  37.42113
                ],
                [
                  104.45543,
                  37.43083
                ],
                [
                  104.43938,
                  37.44716
                ],
                [
                  104.37385,
                  37.42915
                ],
                [
                  104.31812,
                  37.41963
                ],
                [
                  104.30847,
                  37.42527
                ],
                [
                  104.30069,
                  37.43666
                ],
                [
                  104.31268,
                  37.44534
                ],
                [
                  104.32850,
                  37.44712
                ],
                [
                  104.34370,
                  37.44461
                ],
                [
                  104.36072,
                  37.45400
                ],
                [
                  104.37228,
                  37.46165
                ],
                [
                  104.39320,
                  37.46061
                ],
                [
                  104.40576,
                  37.46596
                ],
                [
                  104.41047,
                  37.47546
                ],
                [
                  104.41637,
                  37.50196
                ],
                [
                  104.42712,
                  37.50946
                ],
                [
                  104.47838,
                  37.51280
                ],
                [
                  104.52019,
                  37.51608
                ],
                [
                  104.53344,
                  37.51983
                ],
                [
                  104.55035,
                  37.52392
                ],
                [
                  104.58817,
                  37.53297
                ],
                [
                  104.60126,
                  37.53251
                ],
                [
                  104.63263,
                  37.52423
                ],
                [
                  104.65654,
                  37.51629
                ],
                [
                  104.67664,
                  37.50739
                ],
                [
                  104.69566,
                  37.50718
                ],
                [
                  104.71914,
                  37.51258
                ],
                [
                  104.74233,
                  37.51830
                ],
                [
                  104.76069,
                  37.52559
                ],
                [
                  104.78206,
                  37.53653
                ],
                [
                  104.79508,
                  37.54004
                ],
                [
                  104.82472,
                  37.54034
                ],
                [
                  104.85903,
                  37.54078
                ],
                [
                  104.89963,
                  37.54098
                ],
                [
                  104.95294,
                  37.54276
                ],
                [
                  104.99416,
                  37.54434
                ],
                [
                  105.01979,
                  37.54298
                ],
                [
                  105.04162,
                  37.54140
                ],
                [
                  105.04808,
                  37.55151
                ],
                [
                  105.04953,
                  37.57240
                ],
                [
                  105.05037,
                  37.58835
                ],
                [
                  105.06163,
                  37.61719
                ],
                [
                  105.06992,
                  37.63977
                ],
                [
                  105.08037,
                  37.64893
                ],
                [
                  105.13063,
                  37.65508
                ],
                [
                  105.17037,
                  37.66018
                ],
                [
                  105.18844,
                  37.66664
                ],
                [
                  105.20406,
                  37.67366
                ],
                [
                  105.21823,
                  37.67496
                ],
                [
                  105.24676,
                  37.67592
                ],
                [
                  105.26563,
                  37.67902
                ],
                [
                  105.28499,
                  37.68740
                ],
                [
                  105.30926,
                  37.69919
                ],
                [
                  105.32535,
                  37.70402
                ],
                [
                  105.34430,
                  37.70235
                ],
                [
                  105.37024,
                  37.70384
                ],
                [
                  105.38380,
                  37.70751
                ],
                [
                  105.39745,
                  37.70595
                ],
                [
                  105.40802,
                  37.69912
                ],
                [
                  105.42037,
                  37.69567
                ],
                [
                  105.43839,
                  37.69381
                ],
                [
                  105.45474,
                  37.69028
                ],
                [
                  105.46548,
                  37.68686
                ],
                [
                  105.48530,
                  37.68821
                ],
                [
                  105.51480,
                  37.69287
                ],
                [
                  105.53872,
                  37.69708
                ],
                [
                  105.56158,
                  37.70038
                ],
                [
                  105.58087,
                  37.69913
                ],
                [
                  105.59571,
                  37.69805
                ],
                [
                  105.61778,
                  37.69928
                ],
                [
                  105.63279,
                  37.70056
                ],
                [
                  105.64174,
                  37.70585
                ],
                [
                  105.65162,
                  37.71467
                ],
                [
                  105.65665,
                  37.72325
                ],
                [
                  105.65504,
                  37.72925
                ],
                [
                  105.65034,
                  37.73393
                ],
                [
                  105.64462,
                  37.73814
                ],
                [
                  105.63890,
                  37.74264
                ],
                [
                  105.63337,
                  37.75384
                ],
                [
                  105.63650,
                  37.75931
                ],
                [
                  105.64135,
                  37.76258
                ],
                [
                  105.64649,
                  37.76604
                ],
                [
                  105.65290,
                  37.76668
                ],
                [
                  105.66275,
                  37.76643
                ],
                [
                  105.66524,
                  37.76929
                ],
                [
                  105.66856,
                  37.77122
                ],
                [
                  105.67424,
                  37.77205
                ],
                [
                  105.68196,
                  37.77604
                ],
                [
                  105.68672,
                  37.77823
                ],
                [
                  105.69256,
                  37.77873
                ],
                [
                  105.69576,
                  37.78174
                ],
                [
                  105.69985,
                  37.78424
                ],
                [
                  105.70403,
                  37.78482
                ],
                [
                  105.70892,
                  37.78569
                ],
                [
                  105.71281,
                  37.78768
                ],
                [
                  105.72076,
                  37.78954
                ],
                [
                  105.72786,
                  37.79276
                ],
                [
                  105.73553,
                  37.79450
                ],
                [
                  105.74112,
                  37.79722
                ],
                [
                  105.74573,
                  37.79750
                ],
                [
                  105.75123,
                  37.79748
                ],
                [
                  105.75809,
                  37.79386
                ],
                [
                  105.76601,
                  37.79422
                ],
                [
                  105.76918,
                  37.79682
                ],
                [
                  105.77048,
                  37.80735
                ],
                [
                  105.77045,
                  37.82077
                ],
                [
                  105.77623,
                  37.82981
                ],
                [
                  105.78710,
                  37.84190
                ],
                [
                  105.79498,
                  37.85032
                ],
                [
                  105.80155,
                  37.85959
                ],
                [
                  105.80387,
                  37.86863
                ],
                [
                  105.80381,
                  37.87605
                ],
                [
                  105.80310,
                  37.88536
                ],
                [
                  105.80029,
                  37.89558
                ],
                [
                  105.79668,
                  37.90580
                ],
                [
                  105.79532,
                  37.91402
                ],
                [
                  105.79728,
                  37.92300
                ],
                [
                  105.79974,
                  37.93219
                ],
                [
                  105.79747,
                  37.94716
                ],
                [
                  105.79792,
                  37.95543
                ],
                [
                  105.80759,
                  37.96637
                ],
                [
                  105.81934,
                  37.97976
                ],
                [
                  105.83124,
                  37.99287
                ],
                [
                  105.84200,
                  38.00537
                ],
                [
                  105.84063,
                  38.00858
                ],
                [
                  105.83152,
                  38.01926
                ],
                [
                  105.81916,
                  38.03114
                ],
                [
                  105.80975,
                  38.04106
                ],
                [
                  105.80077,
                  38.05482
                ],
                [
                  105.78621,
                  38.07242
                ],
                [
                  105.77996,
                  38.08766
                ],
                [
                  105.77109,
                  38.11195
                ],
                [
                  105.76397,
                  38.12479
                ],
                [
                  105.76764,
                  38.13830
                ],
                [
                  105.77504,
                  38.18643
                ],
                [
                  105.77922,
                  38.21060
                ],
                [
                  105.78552,
                  38.21277
                ],
                [
                  105.81907,
                  38.22185
                ],
                [
                  105.82652,
                  38.22286
                ],
                [
                  105.83384,
                  38.22984
                ],
                [
                  105.84028,
                  38.23847
                ],
                [
                  105.84455,
                  38.25085
                ],
                [
                  105.85621,
                  38.27402
                ],
                [
                  105.86005,
                  38.28552
                ],
                [
                  105.86266,
                  38.28982
                ],
                [
                  105.86461,
                  38.29715
                ],
                [
                  105.85721,
                  38.30781
                ],
                [
                  105.85337,
                  38.31171
                ],
                [
                  105.84401,
                  38.31958
                ],
                [
                  105.83936,
                  38.32886
                ],
                [
                  105.83421,
                  38.33935
                ],
                [
                  105.82767,
                  38.34600
                ],
                [
                  105.82491,
                  38.35506
                ],
                [
                  105.82026,
                  38.36222
                ],
                [
                  105.82228,
                  38.37132
                ],
                [
                  105.83309,
                  38.38615
                ],
                [
                  105.83272,
                  38.39416
                ],
                [
                  105.83046,
                  38.40144
                ],
                [
                  105.82705,
                  38.40888
                ],
                [
                  105.82769,
                  38.41754
                ],
                [
                  105.82870,
                  38.42841
                ],
                [
                  105.83037,
                  38.43712
                ],
                [
                  105.83734,
                  38.43750
                ],
                [
                  105.84816,
                  38.44252
                ],
                [
                  105.84598,
                  38.45060
                ],
                [
                  105.84264,
                  38.46000
                ],
                [
                  105.83646,
                  38.46915
                ],
                [
                  105.83965,
                  38.48056
                ],
                [
                  105.84312,
                  38.49081
                ],
                [
                  105.84727,
                  38.49859
                ],
                [
                  105.84356,
                  38.50672
                ],
                [
                  105.85083,
                  38.51696
                ],
                [
                  105.86079,
                  38.52496
                ],
                [
                  105.86268,
                  38.53687
                ],
                [
                  105.86187,
                  38.54953
                ],
                [
                  105.85874,
                  38.55794
                ],
                [
                  105.85532,
                  38.56826
                ],
                [
                  105.85837,
                  38.57810
                ],
                [
                  105.87031,
                  38.58192
                ],
                [
                  105.87381,
                  38.59164
                ],
                [
                  105.86601,
                  38.59842
                ],
                [
                  105.86142,
                  38.60919
                ],
                [
                  105.85472,
                  38.61692
                ],
                [
                  105.85348,
                  38.62546
                ],
                [
                  105.85079,
                  38.63537
                ],
                [
                  105.85376,
                  38.64435
                ],
                [
                  105.86288,
                  38.65333
                ],
                [
                  105.87191,
                  38.65852
                ],
                [
                  105.87177,
                  38.66660
                ],
                [
                  105.87482,
                  38.67461
                ],
                [
                  105.88497,
                  38.68296
                ],
                [
                  105.89270,
                  38.69268
                ],
                [
                  105.89000,
                  38.70281
                ],
                [
                  105.88402,
                  38.71553
                ],
                [
                  105.89227,
                  38.72124
                ],
                [
                  105.90082,
                  38.72863
                ],
                [
                  105.90652,
                  38.73910
                ],
                [
                  105.90433,
                  38.74866
                ],
                [
                  105.89981,
                  38.75571
                ],
                [
                  105.90317,
                  38.76532
                ],
                [
                  105.90252,
                  38.77282
                ],
                [
                  105.89608,
                  38.78010
                ],
                [
                  105.89733,
                  38.78942
                ],
                [
                  105.91138,
                  38.79446
                ],
                [
                  105.92345,
                  38.80104
                ],
                [
                  105.93356,
                  38.80310
                ],
                [
                  105.93474,
                  38.80991
                ],
                [
                  105.94237,
                  38.82005
                ],
                [
                  105.95176,
                  38.82887
                ],
                [
                  105.96285,
                  38.83455
                ],
                [
                  105.96836,
                  38.84372
                ],
                [
                  105.98071,
                  38.84860
                ],
                [
                  105.98968,
                  38.85542
                ],
                [
                  105.99506,
                  38.86717
                ],
                [
                  106.00220,
                  38.87463
                ],
                [
                  105.99398,
                  38.87564
                ],
                [
                  105.98156,
                  38.87591
                ],
                [
                  105.97643,
                  38.88626
                ],
                [
                  105.96814,
                  38.89471
                ],
                [
                  105.97219,
                  38.90342
                ],
                [
                  105.98168,
                  38.91036
                ],
                [
                  105.99280,
                  38.92125
                ],
                [
                  106.00104,
                  38.92625
                ],
                [
                  106.00217,
                  38.93987
                ],
                [
                  106.01417,
                  38.94579
                ],
                [
                  106.02640,
                  38.95587
                ],
                [
                  106.03553,
                  38.96155
                ],
                [
                  106.04840,
                  38.96224
                ],
                [
                  106.05774,
                  38.96734
                ],
                [
                  106.06144,
                  38.97776
                ],
                [
                  106.06705,
                  38.98742
                ],
                [
                  106.07529,
                  38.99160
                ],
                [
                  106.08159,
                  39.00103
                ],
                [
                  106.08654,
                  39.00837
                ],
                [
                  106.08750,
                  39.01741
                ],
                [
                  106.07796,
                  39.02462
                ],
                [
                  106.07783,
                  39.03029
                ],
                [
                  106.08437,
                  39.03537
                ],
                [
                  106.07959,
                  39.03875
                ],
                [
                  106.08225,
                  39.05379
                ],
                [
                  106.08940,
                  39.06987
                ],
                [
                  106.09551,
                  39.08028
                ],
                [
                  106.09338,
                  39.08618
                ],
                [
                  106.10743,
                  39.09623
                ],
                [
                  106.11273,
                  39.10853
                ],
                [
                  106.11252,
                  39.11430
                ],
                [
                  106.11113,
                  39.12042
                ],
                [
                  106.12333,
                  39.12607
                ],
                [
                  106.13767,
                  39.14204
                ],
                [
                  106.14458,
                  39.15215
                ],
                [
                  106.16110,
                  39.15839
                ],
                [
                  106.16621,
                  39.15794
                ],
                [
                  106.17143,
                  39.15297
                ],
                [
                  106.17662,
                  39.14735
                ],
                [
                  106.19230,
                  39.14255
                ],
                [
                  106.20731,
                  39.13459
                ],
                [
                  106.21861,
                  39.13410
                ],
                [
                  106.22956,
                  39.13864
                ],
                [
                  106.25004,
                  39.13326
                ],
                [
                  106.25641,
                  39.12694
                ],
                [
                  106.25880,
                  39.13689
                ],
                [
                  106.26175,
                  39.14072
                ],
                [
                  106.26931,
                  39.13641
                ],
                [
                  106.27623,
                  39.13921
                ],
                [
                  106.28287,
                  39.14074
                ],
                [
                  106.28687,
                  39.15040
                ],
                [
                  106.28828,
                  39.15621
                ],
                [
                  106.29459,
                  39.16498
                ],
                [
                  106.28992,
                  39.17545
                ],
                [
                  106.28746,
                  39.18776
                ],
                [
                  106.27909,
                  39.19480
                ],
                [
                  106.27743,
                  39.20470
                ],
                [
                  106.28047,
                  39.20945
                ],
                [
                  106.28788,
                  39.22002
                ],
                [
                  106.28822,
                  39.23185
                ],
                [
                  106.28424,
                  39.23226
                ],
                [
                  106.28063,
                  39.23044
                ],
                [
                  106.27824,
                  39.24474
                ],
                [
                  106.27485,
                  39.26357
                ],
                [
                  106.27789,
                  39.27048
                ],
                [
                  106.30049,
                  39.27554
                ],
                [
                  106.31954,
                  39.27889
                ],
                [
                  106.33617,
                  39.28167
                ],
                [
                  106.34540,
                  39.28280
                ],
                [
                  106.35698,
                  39.28187
                ],
                [
                  106.36525,
                  39.28134
                ],
                [
                  106.38968,
                  39.28436
                ],
                [
                  106.40413,
                  39.28547
                ],
                [
                  106.41850,
                  39.28718
                ],
                [
                  106.43204,
                  39.28768
                ],
                [
                  106.44320,
                  39.28413
                ],
                [
                  106.46295,
                  39.28115
                ],
                [
                  106.46968,
                  39.27745
                ],
                [
                  106.48352,
                  39.27380
                ],
                [
                  106.50507,
                  39.26998
                ],
                [
                  106.51398,
                  39.27786
                ],
                [
                  106.51727,
                  39.28494
                ],
                [
                  106.51982,
                  39.29476
                ],
                [
                  106.52229,
                  39.30205
                ],
                [
                  106.52406,
                  39.30831
                ],
                [
                  106.53221,
                  39.30823
                ],
                [
                  106.53879,
                  39.31562
                ],
                [
                  106.54644,
                  39.31874
                ],
                [
                  106.55467,
                  39.32215
                ],
                [
                  106.56013,
                  39.32800
                ],
                [
                  106.56630,
                  39.33453
                ],
                [
                  106.57201,
                  39.34227
                ],
                [
                  106.58180,
                  39.34891
                ],
                [
                  106.58633,
                  39.35726
                ],
                [
                  106.59530,
                  39.36814
                ],
                [
                  106.60118,
                  39.37548
                ],
                [
                  106.61260,
                  39.37654
                ],
                [
                  106.62294,
                  39.37640
                ],
                [
                  106.63395,
                  39.37603
                ],
                [
                  106.64052,
                  39.37286
                ],
                [
                  106.64731,
                  39.37364
                ],
                [
                  106.65693,
                  39.37653
                ],
                [
                  106.66460,
                  39.37400
                ],
                [
                  106.67486,
                  39.37477
                ],
                [
                  106.68492,
                  39.37480
                ],
                [
                  106.69290,
                  39.37869
                ],
                [
                  106.70151,
                  39.37744
                ],
                [
                  106.71418,
                  39.37578
                ],
                [
                  106.72545,
                  39.37764
                ],
                [
                  106.73503,
                  39.38200
                ],
                [
                  106.74277,
                  39.38655
                ],
                [
                  106.75263,
                  39.38161
                ],
                [
                  106.76763,
                  39.37297
                ],
                [
                  106.79185,
                  39.36024
                ],
                [
                  106.79777,
                  39.34787
                ],
                [
                  106.79391,
                  39.33613
                ],
                [
                  106.79732,
                  39.33012
                ],
                [
                  106.80723,
                  39.31302
                ],
                [
                  106.80201,
                  39.29214
                ],
                [
                  106.80626,
                  39.27329
                ],
                [
                  106.79078,
                  39.24245
                ],
                [
                  106.79740,
                  39.22957
                ],
                [
                  106.82853,
                  39.18347
                ],
                [
                  106.84274,
                  39.14600
                ],
                [
                  106.84355,
                  39.12577
                ],
                [
                  106.86547,
                  39.09754
                ],
                [
                  106.89627,
                  39.08154
                ],
                [
                  106.91492,
                  39.07989
                ],
                [
                  106.94156,
                  39.07085
                ],
                [
                  106.94576,
                  39.06096
                ],
                [
                  106.95671,
                  39.06159
                ],
                [
                  106.97285,
                  39.05117
                ],
                [
                  106.96788,
                  38.98468
                ],
                [
                  106.83275,
                  38.85060
                ],
                [
                  106.80880,
                  38.79818
                ],
                [
                  106.71806,
                  38.71706
                ],
                [
                  106.67856,
                  38.61184
                ],
                [
                  106.66586,
                  38.53157
                ],
                [
                  106.60755,
                  38.39188
                ],
                [
                  106.48581,
                  38.32260
                ],
                [
                  106.50291,
                  38.29913
                ],
                [
                  106.51066,
                  38.29799
                ],
                [
                  106.51604,
                  38.29531
                ],
                [
                  106.52057,
                  38.28993
                ],
                [
                  106.52569,
                  38.28463
                ],
                [
                  106.52441,
                  38.28164
                ],
                [
                  106.52864,
                  38.28082
                ],
                [
                  106.53263,
                  38.27530
                ],
                [
                  106.53676,
                  38.27566
                ],
                [
                  106.54098,
                  38.26802
                ],
                [
                  106.54897,
                  38.26275
                ],
                [
                  106.55639,
                  38.25968
                ],
                [
                  106.56483,
                  38.25831
                ],
                [
                  106.56967,
                  38.25531
                ],
                [
                  106.57420,
                  38.25248
                ],
                [
                  106.57396,
                  38.24975
                ],
                [
                  106.57937,
                  38.24955
                ],
                [
                  106.58846,
                  38.24753
                ],
                [
                  106.59124,
                  38.24180
                ],
                [
                  106.59727,
                  38.23822
                ],
                [
                  106.60802,
                  38.23763
                ],
                [
                  106.62209,
                  38.23016
                ],
                [
                  106.63311,
                  38.22916
                ],
                [
                  106.64122,
                  38.22403
                ],
                [
                  106.65413,
                  38.22789
                ],
                [
                  106.65615,
                  38.22427
                ],
                [
                  106.66431,
                  38.22043
                ],
                [
                  106.68011,
                  38.21244
                ],
                [
                  106.69905,
                  38.20952
                ],
                [
                  106.70662,
                  38.21047
                ],
                [
                  106.70905,
                  38.20705
                ],
                [
                  106.71955,
                  38.19963
                ],
                [
                  106.73909,
                  38.19167
                ],
                [
                  106.74840,
                  38.18855
                ],
                [
                  106.77786,
                  38.16897
                ],
                [
                  106.85575,
                  38.15204
                ],
                [
                  106.97994,
                  38.11892
                ],
                [
                  107.03627,
                  38.11962
                ],
                [
                  107.12290,
                  38.13902
                ],
                [
                  107.13999,
                  38.15776
                ],
                [
                  107.16926,
                  38.15826
                ],
                [
                  107.21335,
                  38.12756
                ],
                [
                  107.27131,
                  38.10115
                ],
                [
                  107.32014,
                  38.08869
                ],
                [
                  107.35241,
                  38.05480
                ],
                [
                  107.39195,
                  38.02103
                ],
                [
                  107.43205,
                  37.99503
                ],
                [
                  107.41942,
                  37.96340
                ],
                [
                  107.41662,
                  37.93714
                ],
                [
                  107.49158,
                  37.94034
                ],
                [
                  107.55904,
                  37.88840
                ],
                [
                  107.64515,
                  37.85952
                ],
                [
                  107.65938,
                  37.85417
                ],
                [
                  107.66070,
                  37.84257
                ],
                [
                  107.65465,
                  37.83224
                ],
                [
                  107.64862,
                  37.82453
                ],
                [
                  107.64584,
                  37.81597
                ],
                [
                  107.64587,
                  37.80689
                ],
                [
                  107.64215,
                  37.79935
                ],
                [
                  107.63605,
                  37.79251
                ],
                [
                  107.62923,
                  37.78418
                ],
                [
                  107.62212,
                  37.77643
                ],
                [
                  107.61180,
                  37.77616
                ],
                [
                  107.60116,
                  37.78454
                ],
                [
                  107.59113,
                  37.78223
                ],
                [
                  107.58613,
                  37.77475
                ],
                [
                  107.57661,
                  37.76868
                ],
                [
                  107.56581,
                  37.76959
                ],
                [
                  107.55180,
                  37.77301
                ],
                [
                  107.53979,
                  37.77330
                ],
                [
                  107.53009,
                  37.77317
                ],
                [
                  107.51536,
                  37.77422
                ],
                [
                  107.50580,
                  37.77382
                ],
                [
                  107.49444,
                  37.76902
                ],
                [
                  107.48443,
                  37.76198
                ],
                [
                  107.48873,
                  37.75304
                ],
                [
                  107.49547,
                  37.74867
                ],
                [
                  107.49955,
                  37.74014
                ],
                [
                  107.49786,
                  37.72780
                ],
                [
                  107.48177,
                  37.72525
                ],
                [
                  107.47128,
                  37.72210
                ],
                [
                  107.47694,
                  37.71614
                ],
                [
                  107.47363,
                  37.71094
                ],
                [
                  107.47153,
                  37.70353
                ],
                [
                  107.46563,
                  37.69732
                ],
                [
                  107.45542,
                  37.69153
                ],
                [
                  107.44528,
                  37.68854
                ],
                [
                  107.43333,
                  37.68526
                ],
                [
                  107.42326,
                  37.68357
                ],
                [
                  107.41340,
                  37.69033
                ],
                [
                  107.40310,
                  37.69386
                ],
                [
                  107.39221,
                  37.69172
                ],
                [
                  107.38362,
                  37.68692
                ],
                [
                  107.38686,
                  37.67761
                ],
                [
                  107.38989,
                  37.66915
                ],
                [
                  107.38959,
                  37.66155
                ],
                [
                  107.38663,
                  37.65086
                ],
                [
                  107.37848,
                  37.64098
                ],
                [
                  107.37385,
                  37.63069
                ],
                [
                  107.36837,
                  37.62018
                ],
                [
                  107.35466,
                  37.61564
                ],
                [
                  107.34348,
                  37.61472
                ],
                [
                  107.33301,
                  37.61391
                ],
                [
                  107.32746,
                  37.61104
                ],
                [
                  107.32241,
                  37.61303
                ],
                [
                  107.31663,
                  37.61320
                ],
                [
                  107.31072,
                  37.61193
                ],
                [
                  107.31288,
                  37.60668
                ],
                [
                  107.31664,
                  37.59679
                ],
                [
                  107.32176,
                  37.58897
                ],
                [
                  107.33243,
                  37.58448
                ],
                [
                  107.34398,
                  37.57956
                ],
                [
                  107.35523,
                  37.57947
                ],
                [
                  107.36251,
                  37.57118
                ],
                [
                  107.36394,
                  37.56245
                ],
                [
                  107.35976,
                  37.55250
                ],
                [
                  107.35529,
                  37.54541
                ],
                [
                  107.34967,
                  37.53414
                ],
                [
                  107.34664,
                  37.52059
                ],
                [
                  107.33749,
                  37.51274
                ],
                [
                  107.33078,
                  37.50468
                ],
                [
                  107.32603,
                  37.49478
                ],
                [
                  107.32012,
                  37.49991
                ],
                [
                  107.31241,
                  37.50134
                ],
                [
                  107.30204,
                  37.49606
                ],
                [
                  107.29117,
                  37.48971
                ],
                [
                  107.28542,
                  37.48169
                ],
                [
                  107.28507,
                  37.47049
                ],
                [
                  107.28637,
                  37.45974
                ],
                [
                  107.28523,
                  37.45054
                ],
                [
                  107.27977,
                  37.43664
                ],
                [
                  107.27698,
                  37.42538
                ],
                [
                  107.28116,
                  37.41538
                ],
                [
                  107.27563,
                  37.40714
                ],
                [
                  107.27543,
                  37.39748
                ],
                [
                  107.26868,
                  37.39130
                ],
                [
                  107.26854,
                  37.38215
                ],
                [
                  107.25993,
                  37.37569
                ],
                [
                  107.25815,
                  37.36476
                ],
                [
                  107.26161,
                  37.35671
                ],
                [
                  107.26234,
                  37.34625
                ],
                [
                  107.25453,
                  37.33401
                ],
                [
                  107.25992,
                  37.32669
                ],
                [
                  107.26014,
                  37.31771
                ],
                [
                  107.26482,
                  37.30588
                ],
                [
                  107.26985,
                  37.29645
                ],
                [
                  107.27705,
                  37.28690
                ],
                [
                  107.28386,
                  37.28372
                ],
                [
                  107.28643,
                  37.28134
                ],
                [
                  107.29249,
                  37.27912
                ],
                [
                  107.29480,
                  37.27446
                ],
                [
                  107.29913,
                  37.27043
                ],
                [
                  107.30188,
                  37.26661
                ],
                [
                  107.30444,
                  37.25938
                ],
                [
                  107.30777,
                  37.25176
                ],
                [
                  107.30927,
                  37.25107
                ],
                [
                  107.30922,
                  37.24718
                ],
                [
                  107.30948,
                  37.24395
                ],
                [
                  107.30861,
                  37.24063
                ],
                [
                  107.30832,
                  37.23824
                ],
                [
                  107.30177,
                  37.23850
                ],
                [
                  107.29093,
                  37.24123
                ],
                [
                  107.28404,
                  37.23590
                ],
                [
                  107.27401,
                  37.23589
                ],
                [
                  107.26828,
                  37.22983
                ],
                [
                  107.27861,
                  37.22314
                ],
                [
                  107.28851,
                  37.21583
                ],
                [
                  107.29971,
                  37.20899
                ],
                [
                  107.30918,
                  37.20243
                ],
                [
                  107.31897,
                  37.19458
                ],
                [
                  107.32325,
                  37.19281
                ],
                [
                  107.32702,
                  37.18436
                ],
                [
                  107.32986,
                  37.18177
                ],
                [
                  107.33120,
                  37.17681
                ],
                [
                  107.33461,
                  37.17188
                ],
                [
                  107.33584,
                  37.16309
                ],
                [
                  107.33582,
                  37.15935
                ],
                [
                  107.33690,
                  37.15484
                ],
                [
                  107.33351,
                  37.15124
                ],
                [
                  107.33654,
                  37.14923
                ],
                [
                  107.33393,
                  37.14666
                ],
                [
                  107.33231,
                  37.14265
                ],
                [
                  107.33343,
                  37.13963
                ],
                [
                  107.33046,
                  37.13736
                ],
                [
                  107.32884,
                  37.13051
                ],
                [
                  107.32463,
                  37.12466
                ],
                [
                  107.31784,
                  37.11708
                ],
                [
                  107.31430,
                  37.11167
                ],
                [
                  107.31081,
                  37.11076
                ],
                [
                  107.30909,
                  37.10360
                ],
                [
                  107.30288,
                  37.10558
                ],
                [
                  107.29569,
                  37.11253
                ],
                [
                  107.29369,
                  37.11436
                ],
                [
                  107.28618,
                  37.12317
                ],
                [
                  107.27527,
                  37.12775
                ],
                [
                  107.27635,
                  37.12084
                ],
                [
                  107.27271,
                  37.11500
                ],
                [
                  107.27472,
                  37.11026
                ],
                [
                  107.27867,
                  37.10191
                ],
                [
                  107.28540,
                  37.09591
                ],
                [
                  107.27530,
                  37.09899
                ],
                [
                  107.26657,
                  37.10132
                ],
                [
                  107.25469,
                  37.10561
                ],
                [
                  107.24497,
                  37.10417
                ],
                [
                  107.23597,
                  37.09811
                ],
                [
                  107.22753,
                  37.10589
                ],
                [
                  107.21937,
                  37.11408
                ],
                [
                  107.20957,
                  37.12129
                ],
                [
                  107.20249,
                  37.12764
                ],
                [
                  107.19176,
                  37.13721
                ],
                [
                  107.18133,
                  37.14460
                ],
                [
                  107.17156,
                  37.14094
                ],
                [
                  107.15742,
                  37.14014
                ],
                [
                  107.14436,
                  37.13793
                ],
                [
                  107.13329,
                  37.13461
                ],
                [
                  107.12001,
                  37.12957
                ],
                [
                  107.10822,
                  37.12635
                ],
                [
                  107.10209,
                  37.12057
                ],
                [
                  107.09178,
                  37.12175
                ],
                [
                  107.08238,
                  37.12813
                ],
                [
                  107.07255,
                  37.12970
                ],
                [
                  107.06246,
                  37.12526
                ],
                [
                  107.05220,
                  37.12746
                ],
                [
                  107.02632,
                  37.13858
                ],
                [
                  107.02408,
                  37.12997
                ],
                [
                  107.03198,
                  37.11190
                ],
                [
                  107.02573,
                  37.11362
                ],
                [
                  106.99733,
                  37.12161
                ],
                [
                  106.99200,
                  37.11751
                ],
                [
                  106.99984,
                  37.10614
                ],
                [
                  106.97047,
                  37.11047
                ],
                [
                  106.96361,
                  37.11794
                ],
                [
                  106.95799,
                  37.12845
                ],
                [
                  106.92406,
                  37.14895
                ],
                [
                  106.90984,
                  37.15049
                ],
                [
                  106.89897,
                  37.14438
                ],
                [
                  106.89673,
                  37.13438
                ],
                [
                  106.91193,
                  37.12806
                ],
                [
                  106.90667,
                  37.11944
                ],
                [
                  106.90400,
                  37.10718
                ],
                [
                  106.88104,
                  37.11252
                ],
                [
                  106.86239,
                  37.11701
                ],
                [
                  106.81752,
                  37.14561
                ],
                [
                  106.78883,
                  37.15756
                ],
                [
                  106.77421,
                  37.14658
                ],
                [
                  106.77104,
                  37.11457
                ],
                [
                  106.76310,
                  37.09630
                ],
                [
                  106.75859,
                  37.09177
                ],
                [
                  106.74175,
                  37.10878
                ],
                [
                  106.73138,
                  37.12041
                ],
                [
                  106.70939,
                  37.12489
                ],
                [
                  106.69948,
                  37.12470
                ],
                [
                  106.68118,
                  37.11736
                ],
                [
                  106.67556,
                  37.12587
                ],
                [
                  106.65488,
                  37.12566
                ],
                [
                  106.63999,
                  37.13484
                ],
                [
                  106.62360,
                  37.13790
                ],
                [
                  106.61578,
                  37.13564
                ],
                [
                  106.60957,
                  37.12734
                ],
                [
                  106.61353,
                  37.11522
                ],
                [
                  106.62498,
                  37.09794
                ],
                [
                  106.64421,
                  37.07681
                ],
                [
                  106.65134,
                  37.06439
                ],
                [
                  106.65225,
                  37.03961
                ],
                [
                  106.66652,
                  37.01860
                ],
                [
                  106.65403,
                  37.01319
                ],
                [
                  106.64632,
                  36.99013
                ],
                [
                  106.64172,
                  36.97452
                ],
                [
                  106.64336,
                  36.96635
                ],
                [
                  106.62688,
                  36.96116
                ],
                [
                  106.60370,
                  36.96832
                ],
                [
                  106.59423,
                  36.96482
                ],
                [
                  106.59415,
                  36.95139
                ],
                [
                  106.59159,
                  36.94147
                ],
                [
                  106.58737,
                  36.94651
                ],
                [
                  106.57225,
                  36.96508
                ],
                [
                  106.54710,
                  36.98786
                ],
                [
                  106.54217,
                  36.97846
                ],
                [
                  106.55450,
                  36.95462
                ],
                [
                  106.55247,
                  36.94357
                ],
                [
                  106.56235,
                  36.93357
                ],
                [
                  106.57891,
                  36.92212
                ],
                [
                  106.61682,
                  36.87677
                ],
                [
                  106.62532,
                  36.88418
                ],
                [
                  106.63167,
                  36.88549
                ],
                [
                  106.63234,
                  36.87376
                ],
                [
                  106.63204,
                  36.86389
                ],
                [
                  106.63044,
                  36.85563
                ],
                [
                  106.64144,
                  36.84886
                ],
                [
                  106.64179,
                  36.83700
                ],
                [
                  106.63901,
                  36.82943
                ],
                [
                  106.65604,
                  36.82346
                ],
                [
                  106.65735,
                  36.81403
                ],
                [
                  106.64993,
                  36.80793
                ],
                [
                  106.64564,
                  36.80140
                ],
                [
                  106.63996,
                  36.78431
                ],
                [
                  106.63674,
                  36.76310
                ],
                [
                  106.63168,
                  36.75766
                ],
                [
                  106.62938,
                  36.74776
                ],
                [
                  106.64518,
                  36.73123
                ],
                [
                  106.64862,
                  36.72356
                ],
                [
                  106.63199,
                  36.73156
                ],
                [
                  106.61840,
                  36.73945
                ],
                [
                  106.60860,
                  36.73891
                ],
                [
                  106.59327,
                  36.75123
                ],
                [
                  106.58044,
                  36.75546
                ],
                [
                  106.57432,
                  36.74823
                ],
                [
                  106.57143,
                  36.73993
                ],
                [
                  106.55935,
                  36.74364
                ],
                [
                  106.55355,
                  36.73676
                ],
                [
                  106.54192,
                  36.73509
                ],
                [
                  106.53169,
                  36.73074
                ],
                [
                  106.51945,
                  36.71238
                ],
                [
                  106.52908,
                  36.69162
                ],
                [
                  106.52013,
                  36.69386
                ],
                [
                  106.49181,
                  36.68933
                ],
                [
                  106.49270,
                  36.67207
                ],
                [
                  106.49175,
                  36.66320
                ],
                [
                  106.49662,
                  36.65311
                ],
                [
                  106.49501,
                  36.64958
                ],
                [
                  106.48441,
                  36.64504
                ],
                [
                  106.48620,
                  36.63644
                ],
                [
                  106.48180,
                  36.62899
                ],
                [
                  106.45843,
                  36.62617
                ],
                [
                  106.44056,
                  36.62847
                ],
                [
                  106.44143,
                  36.61786
                ],
                [
                  106.44309,
                  36.60983
                ],
                [
                  106.44305,
                  36.60072
                ],
                [
                  106.44947,
                  36.59525
                ],
                [
                  106.45576,
                  36.59223
                ],
                [
                  106.45815,
                  36.58535
                ],
                [
                  106.44969,
                  36.58099
                ],
                [
                  106.44946,
                  36.57509
                ],
                [
                  106.45441,
                  36.56483
                ],
                [
                  106.44114,
                  36.56248
                ],
                [
                  106.42028,
                  36.56840
                ],
                [
                  106.40452,
                  36.58035
                ],
                [
                  106.39522,
                  36.57115
                ],
                [
                  106.39249,
                  36.56322
                ],
                [
                  106.38239,
                  36.57454
                ],
                [
                  106.36644,
                  36.58012
                ],
                [
                  106.36085,
                  36.57494
                ],
                [
                  106.36971,
                  36.55933
                ],
                [
                  106.37113,
                  36.55020
                ],
                [
                  106.38370,
                  36.54459
                ],
                [
                  106.39212,
                  36.54623
                ],
                [
                  106.39856,
                  36.54066
                ],
                [
                  106.40904,
                  36.53507
                ],
                [
                  106.41513,
                  36.52974
                ],
                [
                  106.40992,
                  36.52473
                ],
                [
                  106.40858,
                  36.51995
                ],
                [
                  106.41592,
                  36.51574
                ],
                [
                  106.42463,
                  36.51404
                ],
                [
                  106.43097,
                  36.51738
                ],
                [
                  106.43461,
                  36.51434
                ],
                [
                  106.44265,
                  36.50628
                ],
                [
                  106.45244,
                  36.49758
                ],
                [
                  106.45987,
                  36.49833
                ],
                [
                  106.46234,
                  36.49626
                ],
                [
                  106.46785,
                  36.49605
                ],
                [
                  106.47357,
                  36.49527
                ],
                [
                  106.47686,
                  36.48740
                ],
                [
                  106.48234,
                  36.48094
                ],
                [
                  106.49011,
                  36.48285
                ],
                [
                  106.49375,
                  36.49212
                ],
                [
                  106.49860,
                  36.48865
                ],
                [
                  106.51446,
                  36.48085
                ],
                [
                  106.51668,
                  36.47353
                ],
                [
                  106.52014,
                  36.46741
                ],
                [
                  106.51618,
                  36.45719
                ],
                [
                  106.50284,
                  36.44795
                ],
                [
                  106.49844,
                  36.43607
                ],
                [
                  106.50460,
                  36.42991
                ],
                [
                  106.50868,
                  36.42157
                ],
                [
                  106.50203,
                  36.42284
                ],
                [
                  106.49081,
                  36.42656
                ],
                [
                  106.48738,
                  36.41799
                ],
                [
                  106.48206,
                  36.41098
                ],
                [
                  106.48559,
                  36.39938
                ],
                [
                  106.49755,
                  36.39940
                ],
                [
                  106.50639,
                  36.39664
                ],
                [
                  106.51653,
                  36.38905
                ],
                [
                  106.52128,
                  36.38627
                ],
                [
                  106.52700,
                  36.37820
                ],
                [
                  106.51235,
                  36.38235
                ],
                [
                  106.50363,
                  36.37737
                ],
                [
                  106.49597,
                  36.32763
                ],
                [
                  106.50162,
                  36.30486
                ],
                [
                  106.48544,
                  36.31665
                ],
                [
                  106.46864,
                  36.31245
                ],
                [
                  106.47546,
                  36.29877
                ],
                [
                  106.48077,
                  36.28400
                ],
                [
                  106.49447,
                  36.27181
                ],
                [
                  106.50322,
                  36.26313
                ],
                [
                  106.51107,
                  36.27086
                ],
                [
                  106.51840,
                  36.26740
                ],
                [
                  106.52666,
                  36.26109
                ],
                [
                  106.53685,
                  36.25257
                ],
                [
                  106.54218,
                  36.25410
                ],
                [
                  106.54708,
                  36.25741
                ],
                [
                  106.55736,
                  36.26250
                ],
                [
                  106.56218,
                  36.27602
                ],
                [
                  106.55989,
                  36.28750
                ],
                [
                  106.56603,
                  36.28959
                ],
                [
                  106.57973,
                  36.28040
                ],
                [
                  106.59128,
                  36.26973
                ],
                [
                  106.59750,
                  36.28400
                ],
                [
                  106.60061,
                  36.29123
                ],
                [
                  106.60731,
                  36.28020
                ],
                [
                  106.62392,
                  36.26875
                ],
                [
                  106.63718,
                  36.26738
                ],
                [
                  106.64179,
                  36.27878
                ],
                [
                  106.64257,
                  36.26453
                ],
                [
                  106.65228,
                  36.25663
                ],
                [
                  106.66810,
                  36.26475
                ],
                [
                  106.67930,
                  36.26955
                ],
                [
                  106.69551,
                  36.24472
                ],
                [
                  106.72011,
                  36.22546
                ],
                [
                  106.73153,
                  36.22896
                ],
                [
                  106.76022,
                  36.21380
                ],
                [
                  106.77412,
                  36.21189
                ],
                [
                  106.77949,
                  36.21651
                ],
                [
                  106.78821,
                  36.22088
                ],
                [
                  106.80138,
                  36.20855
                ],
                [
                  106.80940,
                  36.21120
                ],
                [
                  106.81640,
                  36.21543
                ],
                [
                  106.82610,
                  36.21036
                ],
                [
                  106.82828,
                  36.22686
                ],
                [
                  106.83231,
                  36.22820
                ],
                [
                  106.84230,
                  36.21749
                ],
                [
                  106.84553,
                  36.19876
                ],
                [
                  106.85804,
                  36.19554
                ],
                [
                  106.87033,
                  36.18550
                ],
                [
                  106.87691,
                  36.16876
                ],
                [
                  106.88513,
                  36.16807
                ],
                [
                  106.89508,
                  36.16131
                ],
                [
                  106.90349,
                  36.16301
                ],
                [
                  106.91131,
                  36.15411
                ],
                [
                  106.91309,
                  36.14776
                ],
                [
                  106.91492,
                  36.12489
                ],
                [
                  106.92473,
                  36.12094
                ],
                [
                  106.92721,
                  36.12805
                ],
                [
                  106.93477,
                  36.13194
                ],
                [
                  106.93628,
                  36.12939
                ],
                [
                  106.93110,
                  36.12074
                ],
                [
                  106.92883,
                  36.11169
                ],
                [
                  106.93487,
                  36.10985
                ],
                [
                  106.94204,
                  36.11999
                ],
                [
                  106.94609,
                  36.12080
                ],
                [
                  106.94792,
                  36.11722
                ],
                [
                  106.94328,
                  36.10712
                ],
                [
                  106.94473,
                  36.09580
                ],
                [
                  106.95099,
                  36.09349
                ],
                [
                  106.95107,
                  36.08302
                ],
                [
                  106.94775,
                  36.07388
                ],
                [
                  106.94429,
                  36.06402
                ],
                [
                  106.93750,
                  36.05677
                ],
                [
                  106.94529,
                  36.05020
                ],
                [
                  106.94391,
                  36.03768
                ],
                [
                  106.93506,
                  36.02872
                ],
                [
                  106.92783,
                  36.02284
                ],
                [
                  106.92864,
                  36.00899
                ],
                [
                  106.94347,
                  36.00044
                ],
                [
                  106.94967,
                  35.99520
                ],
                [
                  106.93688,
                  35.98045
                ],
                [
                  106.93235,
                  35.97464
                ],
                [
                  106.92658,
                  35.97386
                ],
                [
                  106.92474,
                  35.96924
                ],
                [
                  106.92040,
                  35.96505
                ],
                [
                  106.91948,
                  35.95234
                ],
                [
                  106.91473,
                  35.94626
                ],
                [
                  106.91538,
                  35.95317
                ],
                [
                  106.91083,
                  35.96412
                ],
                [
                  106.90160,
                  35.96601
                ],
                [
                  106.89449,
                  35.96566
                ],
                [
                  106.89955,
                  35.94664
                ],
                [
                  106.91314,
                  35.93599
                ],
                [
                  106.92932,
                  35.93959
                ],
                [
                  106.93639,
                  35.94552
                ],
                [
                  106.94361,
                  35.93794
                ],
                [
                  106.93417,
                  35.93190
                ],
                [
                  106.92171,
                  35.92950
                ],
                [
                  106.90855,
                  35.92616
                ],
                [
                  106.90628,
                  35.92184
                ],
                [
                  106.90429,
                  35.91633
                ],
                [
                  106.90087,
                  35.91238
                ],
                [
                  106.88929,
                  35.91766
                ],
                [
                  106.87171,
                  35.90647
                ],
                [
                  106.86168,
                  35.90715
                ],
                [
                  106.85995,
                  35.89615
                ],
                [
                  106.85294,
                  35.88616
                ],
                [
                  106.85412,
                  35.87776
                ],
                [
                  106.87278,
                  35.87337
                ],
                [
                  106.88065,
                  35.87559
                ],
                [
                  106.88518,
                  35.86892
                ],
                [
                  106.87062,
                  35.87034
                ],
                [
                  106.87170,
                  35.86159
                ],
                [
                  106.88259,
                  35.84454
                ],
                [
                  106.89612,
                  35.83098
                ],
                [
                  106.90275,
                  35.82154
                ],
                [
                  106.91397,
                  35.82530
                ],
                [
                  106.91715,
                  35.81821
                ],
                [
                  106.91699,
                  35.80940
                ],
                [
                  106.92556,
                  35.80494
                ],
                [
                  106.91893,
                  35.80207
                ],
                [
                  106.90264,
                  35.80865
                ],
                [
                  106.89573,
                  35.80269
                ],
                [
                  106.89978,
                  35.79729
                ],
                [
                  106.90360,
                  35.79291
                ],
                [
                  106.90776,
                  35.78729
                ],
                [
                  106.91434,
                  35.78666
                ],
                [
                  106.91525,
                  35.77799
                ],
                [
                  106.91471,
                  35.77546
                ],
                [
                  106.91126,
                  35.77330
                ],
                [
                  106.91180,
                  35.76693
                ],
                [
                  106.90710,
                  35.77325
                ],
                [
                  106.90085,
                  35.77251
                ],
                [
                  106.89912,
                  35.75815
                ],
                [
                  106.89146,
                  35.75709
                ],
                [
                  106.89109,
                  35.76492
                ],
                [
                  106.87906,
                  35.77544
                ],
                [
                  106.87011,
                  35.77525
                ],
                [
                  106.86672,
                  35.76095
                ],
                [
                  106.86644,
                  35.74052
                ],
                [
                  106.85773,
                  35.74076
                ],
                [
                  106.84181,
                  35.74275
                ],
                [
                  106.83591,
                  35.74033
                ],
                [
                  106.83413,
                  35.73858
                ],
                [
                  106.83078,
                  35.74447
                ],
                [
                  106.81793,
                  35.74448
                ],
                [
                  106.81638,
                  35.73704
                ],
                [
                  106.82204,
                  35.72773
                ],
                [
                  106.81988,
                  35.72305
                ],
                [
                  106.81988,
                  35.71544
                ],
                [
                  106.80996,
                  35.70983
                ],
                [
                  106.80316,
                  35.71188
                ],
                [
                  106.79793,
                  35.71638
                ],
                [
                  106.78866,
                  35.72194
                ],
                [
                  106.78035,
                  35.72211
                ],
                [
                  106.77178,
                  35.72674
                ],
                [
                  106.76212,
                  35.72278
                ],
                [
                  106.75527,
                  35.72425
                ],
                [
                  106.75015,
                  35.71117
                ],
                [
                  106.74529,
                  35.70023
                ],
                [
                  106.74442,
                  35.69384
                ],
                [
                  106.75348,
                  35.69114
                ],
                [
                  106.74113,
                  35.69089
                ],
                [
                  106.73651,
                  35.68925
                ],
                [
                  106.73415,
                  35.69107
                ],
                [
                  106.73064,
                  35.68895
                ],
                [
                  106.72581,
                  35.69477
                ],
                [
                  106.68987,
                  35.72158
                ],
                [
                  106.68379,
                  35.72350
                ],
                [
                  106.68399,
                  35.72671
                ],
                [
                  106.67689,
                  35.72802
                ],
                [
                  106.66927,
                  35.71862
                ],
                [
                  106.67106,
                  35.71206
                ],
                [
                  106.66876,
                  35.71246
                ],
                [
                  106.66814,
                  35.70912
                ],
                [
                  106.66382,
                  35.70943
                ],
                [
                  106.65711,
                  35.70943
                ],
                [
                  106.64888,
                  35.71487
                ],
                [
                  106.64571,
                  35.70927
                ],
                [
                  106.64236,
                  35.71201
                ],
                [
                  106.63073,
                  35.71648
                ],
                [
                  106.62659,
                  35.72394
                ],
                [
                  106.62288,
                  35.74121
                ],
                [
                  106.61557,
                  35.74205
                ],
                [
                  106.61284,
                  35.73305
                ],
                [
                  106.61136,
                  35.73114
                ],
                [
                  106.60995,
                  35.73661
                ],
                [
                  106.60387,
                  35.74467
                ],
                [
                  106.59677,
                  35.74094
                ],
                [
                  106.59895,
                  35.73109
                ],
                [
                  106.59444,
                  35.72762
                ],
                [
                  106.58141,
                  35.73145
                ],
                [
                  106.57581,
                  35.73415
                ],
                [
                  106.56539,
                  35.73837
                ],
                [
                  106.55452,
                  35.73921
                ],
                [
                  106.54284,
                  35.74044
                ],
                [
                  106.52872,
                  35.74119
                ],
                [
                  106.52864,
                  35.73547
                ],
                [
                  106.53113,
                  35.72375
                ],
                [
                  106.52556,
                  35.73816
                ],
                [
                  106.51938,
                  35.73947
                ],
                [
                  106.51140,
                  35.73649
                ],
                [
                  106.50559,
                  35.73580
                ],
                [
                  106.49958,
                  35.73431
                ],
                [
                  106.49264,
                  35.73126
                ],
                [
                  106.49022,
                  35.72480
                ],
                [
                  106.49046,
                  35.72041
                ],
                [
                  106.48598,
                  35.72337
                ],
                [
                  106.47994,
                  35.72213
                ],
                [
                  106.46771,
                  35.71839
                ]
              ]
            ]
          ]
        }
      }
    ]
  }

  export default {
    name: 'CenterMap',
    data () {
      return {
        scene: null
      }
    },
    mounted () {
      this.scene = new Scene({
        id: 'map',
        map: new GaodeMap({
          pitch: 0,
          /*style: 'dark',*/
          style: 'amap://styles/darkblue',
          center: [106.39297485351561, 38.54198948702892],
          zoom: 10.5
        })
      })
      this.init()
    },
    methods: {
      init () {
        this.loadPoint()
        /*this.loadPoint2()*/
        this.loadPolygon()
        this.loadLine()
      },
      loadPolygon () {
        const polygonLayer = new PolygonLayer({
          autoFit: true
        })
          .source(polygonData)
          .color(
            'name',
            [
              'rgb(49,130,189)',
              'rgb(8,81,156)'
            ]
          )
          .shape('fill')
          .style({
            opacity: 0.1
          })
        this.scene.addLayer(polygonLayer)
        const polygonLayerLine = new PolygonLayer({
          autoFit: true
        })
          .source(polygonData)
          .color(
            'name',
            [
              'rgb(8,81,156)'
            ]
          )
          .shape('line')
          .size(1.5)
          .style({
            opacity: 0.6
          })
        this.scene.addLayer(polygonLayer)
        this.scene.addLayer(polygonLayerLine)
      },
      loadPoint () {
        this.scene.on('loaded', () => {
          fetch(
            '/mapdata/ningxia-points.csv'
          )
            .then(res => res.text())
            .then(data => {
              const pointLayer = new PointLayer({})
                .source(data, {
                  parser: {
                    type: 'csv',
                    x: 'lon',
                    y: 'lat'
                  }
                })
                .shape('circle')
                .active(true)
                .animate(true)
                .size(30)
                .color('#4cfd47')
                .style({
                  opacity: 1
                })
              this.scene.addLayer(pointLayer)
            })
        })
      },
      loadPoint2 () {
        const pointLayer = new PointLayer({})
          .source(pointData)
          .shape('circle')
          .active(true)
          .animate(true)
          .size(34)
          .color('#4cfd47')
          .style({
            opacity: 1
          })
        this.scene.addLayer(pointLayer)
      },
      loadLine () {
        /*
        duration 动画时间 单位(s)秒
        interval 轨迹间隔, 取值区间 0 - 1
        trailLength 轨迹长度 取值区间 0 - 1*/
        const lineLayer = new LineLayer({})
          .source(lineData)
          .active(true)
          .animate({
            duration: 3,
            interval: 2,
            trailLength: 0.4,
          })
          .color('#4cfd47')
          .size(1.5)
          .style({
            opacity: 1
          })
        this.scene.addLayer(lineLayer)
      }
    }
  }
</script>

<style>
</style>