<template>
  <div class="view-box">
    <div class="view-box-title">预警信息</div>
    <div class="view-box-content" id="scroll-board" style="padding: 0px">
      <dv-scroll-board :config="config"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'rightThree',
    data () {
      return {
        config: {
          header: ['预警时间', '预警位置', '预警内容'],
          data: [
            ['2020-05-22 19:15:00', '沉淀池', '水平流速过慢'],
            ['2020-05-23 12:14:00', '水解酸化池', '水力停留时间异常'],
            ['2020-05-24 16:29:00', '生物接触氧化池', '污泥回流'],
            ['2020-05-25 15:25:00', '物理脱水压滤机', '传动链松动'],
            ['2020-05-26 14:28:00', '沉砂池', '无机颗粒下沉异常'],
            ['2020-05-27 13:25:00', '生物转盘', '接触反应槽异常'],
            ['2020-05-22 12:46:00', '二沉池', '分离受阻'],
            ['2020-05-23 11:55:00', '污水处理池', '处理缓慢'],
            ['2020-05-24 13:26:00', '污水提升泵', '驱动连杆异常'],
            ['2020-05-25 09:49:00', '离心式脱水机', '电压持续波动'],
            ['2020-05-26 14:18:00', '高效沉淀池', '沉淀分离'],
            ['2020-05-27 07:46:00', '物理脱水压滤机', '传动链松动'],
          ],
          index: true,
          columnWidth: [50, 170, 180, 160],
          align: ['center'],
          headerHeight: 35,
          rowNum: 8,
          carousel: 'page',
          headerBGC: 'rgba(0, 44, 81, 0.8)',
          oddRowBGC: 'rgba(0, 44, 81, 0.8)',
          evenRowBGC: 'rgba(10, 29, 50, 0.8)'
        }
      }
    }
  }
</script>

<style lang="less">
  #scroll-board {
    height: 332px;
    overflow: hidden;
  }

  .layout {
    .ant-layout {
      .header {
        height: auto;
      }
    }
  }
</style>
